import { UpdateTaskRequest } from "@9amhealth/openapi";
import {
  NineActions,
  NineButton,
  NineHeading
} from "@9amhealth/wcl/generated/react";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import FileType from "src/constants/fileType";
import translate from "src/lib/translate";
import waitSeconds from "src/lib/waitSeconds";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { taskManagementState, tracker, websocketState } from "src/state/state";
import { TaskKey } from "src/state/TaskManagementBloc/TaskManagementBloc";
import type { TrackEvent } from "src/state/Track/TrackCubit";
import { WebsocketMessageType } from "src/state/WebSocketBloc/WebSocketBloc";
import { AppQueryPopupsController } from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import BlockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import { ChatMessageMetaType } from "src/ui/components/Chat/ChatBloc";
import type { SingleFileConfig } from "src/ui/components/DocumentUploader/DocumentUploader";
import DocumentUploader from "src/ui/components/DocumentUploader/DocumentUploader";
import InDialog from "src/ui/components/InDialog/InDialog";
import { ClearButton } from "src/ui/components/PharmacyForm/PharmacyForm";
import Translate from "src/ui/components/Translate/Translate";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { v4 as uuidv4 } from "uuid";

const ChatUploadLabResultDialog: FC<{ returnUrl: string }> = ({
  returnUrl
}) => {
  const documentSessionId = useMemo<string>(() => uuidv4(), []);
  const [fileIds, setFileIds] = React.useState<Record<string, string>>({});
  const navigate = useGoToOrBack();
  const [modalRef, setModalRef] =
    React.useState<React.RefObject<HTMLIonModalElement> | null>(null);

  const showSkip = useSearchParams()[0].get("showSkip") === "true";

  const handleSkip = () => {
    tracker.track("Lab-Report-Upload Skipped" as TrackEvent);
    void taskManagementState
      .updateTaskStatus(
        {
          program: KnownProgram.ONBOARDING,
          group: "labs",
          slug: TaskKey.UPLOAD_LABS
        },
        UpdateTaskRequest.status.SKIPPED
      )
      .then(() => {
        AppQueryPopupsController.closePopup();
      });
  };

  const onClose = () => {
    void modalRef?.current?.dismiss();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const handleFileUploadChange = (files: Record<string, string>) => {
    const filesEmptyValuesRemoved = Object.fromEntries(
      Object.entries(files).filter(([, value]) => value)
    );
    setFileIds(filesEmptyValuesRemoved);
  };

  const documents = useMemo(() => {
    const docs: Record<string, SingleFileConfig> = {};

    // fill with fieldIds
    for (const key in fileIds) {
      if (!fileIds[key]) continue;
      docs[key] = {
        title: translate("uploadPage", { number: 1 })
      };
    }

    if (Object.keys(fileIds).length === 0) {
      docs[Date.now()] = { title: translate("uploadPage", { number: 1 }) };
    } else {
      docs[Date.now()] = {
        title: "",
        icon: "plus"
      };
    }

    // add file type and tags
    for (const key in docs) {
      docs[key].metaFileType = FileType.LAB_RESULT;
      docs[key].tags = [
        "lab-report",
        `page-create-time-${key}`,
        `document-upload-session:${documentSessionId}`
      ];
    }

    return docs;
  }, [fileIds]);

  const handleSave = async () => {
    const [loadingDone, loadingError] =
      BlockingLoadingOverlayController.startLoading();
    const metadata = {
      filesIdList: Object.values(fileIds),
      "message:type": ChatMessageMetaType.labReportUpload,
      "content-value:generated": true
    };

    // Clear list of file ids to prevent uploading duplicate lab reports in closeHandler; we don't need files anymore anyway
    setFileIds({});

    const sendMessage = {
      type: WebsocketMessageType.sendMessage,
      payload: {
        contentType: "text/html",
        contentValue: "Uploaded lab report",
        metadata
      }
    };

    try {
      await websocketState.send(sendMessage);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      loadingError({
        message: translate("chat.labUpload.error")
      });
    }

    await waitSeconds(0.5);

    void modalRef?.current?.dismiss();
    loadingDone();
  };

  useEffect(() => {
    setFileIds({});
  }, []);

  const closeHandler = () => {
    if (Object.keys(fileIds).length === 0) {
      onClose();
    } else {
      void handleSave();
    }
  };

  return (
    <InDialog
      popup
      title={translate("chat.labReport.title")}
      returnUrl={returnUrl}
      onClose={closeHandler}
      setRef={setModalRef}
    >
      <nine-spacer s="md"></nine-spacer>
      <NineHeading>
        <h3 className="as-h4-large">
          <Translate msg="chat.selectPhoto" />
        </h3>
      </NineHeading>
      <DocumentUploader
        display={{
          ratio: 0.8,
          showSelectedTitles: false,
          action: "delete"
        }}
        config={{
          fileTypes: [
            "image/jpeg",
            "image/png",
            "application/pdf",
            "image/jpg"
          ],
          files: documents
        }}
        onChange={handleFileUploadChange}
      />

      <NineActions style={{ justifyContent: "center" }}>
        <NineButton
          disabled={Object.keys(fileIds).length === 0 ? "true" : "false"}
          onClick={handleSave}
        >
          <Translate msg="confirm" />
        </NineButton>
      </NineActions>

      {showSkip && (
        <div style={{ textAlign: "center" }}>
          <nine-spacer s="lg"></nine-spacer>
          <p className="m0 color-c-80">
            {translate("task.skip.question", { context: "upload_labs" })}
          </p>
          <nine-spacer s="xxxs"></nine-spacer>
          <ClearButton
            style={{ display: "block", margin: "0 auto" }}
            onClick={handleSkip}
          >
            {translate("task.skip.button_text")}
          </ClearButton>
        </div>
      )}
    </InDialog>
  );
};

export default ChatUploadLabResultDialog;
